<template>
  <div class="classroom-revert-detail">
    <div class="main">
      <div class="classroom-info">
        <div class="title">课室信息</div>
        <div class="content">
          <div class="c-item">{{ detail.name }}</div>
          <!-- <div class="c-item">地址:{{detail.province}}{{detail.city}}{{detail.area}}{{detail.buildingName}}{{detail.doorplateNumber}} </div> -->
          <div class="c-item area-people">
            <div>课室面积: {{ detail.acreage }}m²</div>
            <div>容纳人数: {{ detail.capacity }}位</div>
          </div>
          <div class="c-item area-people">
            <span>可用设备</span>
            <span class="text-button"
                  @click="showDialogEdit=true">查看</span>
          </div>
          <div class="c-item">课室说明: {{ detail.remark }}</div>
          <div class="c-item">
            <div>课室图片:</div>
            <div class="pictures">
              <div class="picture-item"
                   v-for="(item, index) in pictures"
                   :key="index">
                <div class="picture-item__content">
                  <van-image class="picture"
                             @click="pictureClick(index)"
                             fit="cover"
                             :src="item" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="base-info classroom-info">
        <div class="title">选择预约时间</div>
        <div class="content">
          <van-radio-group v-model="radioDatesInd">
            <van-cell-group>
              <template v-for="(items,inds) in appointment.dates">
                <van-cell clickable
                          :key="inds"
                          @click="()=>{
                            if(items.borrowDate==nowTime) radioDatesInd =inds+1
                          }">
                  <template #title>
                    <div> {{ items.borrowStartTime }}~{{ items.borrowEndTime.slice(11)}}</div>
                  </template>
                  <template #right-icon>
                    <van-radio :name="inds+1"
                               :disabled="items.borrowDate!=nowTime" />
                  </template>
                </van-cell>
              </template>

            </van-cell-group>
          </van-radio-group>
        </div>
      </div>

      <div class="base-info classroom-info">
        <div class="title">预约信息</div>
        <div class="content">
          <div class="c-item  content-item">
            <span class="name">使用时间:</span>
            <div class="value"
                 v-if="radioDatesInd">
              {{ appointment.dates[radioDatesInd-1].borrowStartTime }}~{{  appointment.dates[radioDatesInd-1].borrowEndTime.slice(11)}}
            </div>
          </div>
          <div class="c-item content-item">
            <span class="name">开门时间: </span>
            <span class="value"
                  v-if="radioDatesInd">
              {{ appointment.dates[radioDatesInd-1].openDoorDatetime }}</span>
          </div>
          <div class="c-item content-item">
            <span class="name">归还时间: </span>
            <span class="value"
                  v-if="radioDatesInd">
              {{ appointment.dates[radioDatesInd-1].closeDoorDatetime }}</span>
          </div>
          <div class="c-item content-item">
            <span class="name">检查时间: </span>
            <span class="value"
                  v-if="radioDatesInd">
              {{ appointment.dates[radioDatesInd-1].confirmDatetime }}</span>
          </div>
          <div class="c-item content-item">
            <span class="name">姓名:</span>
            <span class="value">
              {{ appointment.contactName }}</span>
          </div>
          <div class="c-item content-item">
            <span class="name">手机号码:</span>
            <span class="value">
              {{ appointment.contactMobileNumber }}</span>
          </div>
        </div>
      </div>

      <div class="base-info classroom-info"
           v-if="personnel=='user'">
        <div class="title">门锁信息</div>
        <div class="content">
          <van-cell-group v-for="(item,index) in lockList"
                          :key="item.id">
            <van-field label-width='80px'
                       label="门锁名称"
                       :value="item.name||'门锁-'+(index+1)"
                       readonly />
            <van-field label-width='80px'
                       label="正门密码"
                       :value="item.password||'点击获取门锁密码'"
                       readonly>
              <template #button>
                <van-button size="small"
                            :disabled="!radioDatesInd||!!item.password"
                            @click="getPasswordByLockId(index,radioDatesInd-1)"
                            type="primary">获取密码</van-button>
              </template>
            </van-field>
          </van-cell-group>
        </div>
      </div>
    </div>
    <div class="footer"
         v-if="personnel=='user'">
      <van-button v-if="radioDatesInd&&!appointment.dates[radioDatesInd-1].openDoorDatetime"
                  class="submit"
                  type="info"
                  round
                  :disabled="isClick"
                  @click="submit('USING')">确认使用</van-button>
      <van-button v-if="radioDatesInd&&appointment.dates[radioDatesInd-1].openDoorDatetime&&!appointment.dates[radioDatesInd-1].closeDoorDatetime"
                  class="submit"
                  type="info"
                  :disabled="isClick"
                  @click="submit('RETURNED')"
                  round>确认归还</van-button>
    </div>
    <div class="footer"
         v-if="personnel=='teacher'">
      <van-button v-if="radioDatesInd&&appointment.dates[radioDatesInd-1].openDoorDatetime&&appointment.dates[radioDatesInd-1].closeDoorDatetime&&appointment.dates[radioDatesInd-1].status=='EFFECTIVE'"
                  class="submit"
                  type="info"
                  round
                  :disabled="isClick"
                  @click="submit('CHECKED')">设备确认</van-button>
    </div>
    <van-dialog v-model="showDialog"
                show-cancel-button
                @confirm="dialogConfirm"
                title="请确认课室设备清单">
      <div class="dialog-conent">
        <van-checkbox v-model="isCheckedAll"
                      shape="square"
                      @click="toggleAll">全选</van-checkbox>
        <van-checkbox-group v-model="checkboxList"
                            @change="changeCheckbox"
                            ref="checkboxGroup">
          <template v-for="item in facility">
            <van-checkbox :name="item.id"
                          shape="square">
              <div class="conent-line">
                <span class="name">{{item.name}} X{{item.number}}</span>
                <div class="input"
                     @click.stop="()=>{}">
                  <van-field v-model="item.remark"
                             label-width="0"
                             placeholder="备注"></van-field>
                </div>

              </div>
            </van-checkbox>
          </template>
        </van-checkbox-group>
      </div>
    </van-dialog>

    <!-- 查看 -->
    <van-dialog v-model="showDialogEdit"
                :show-confirm-button="false"
                :show-cancel-button="true"
                title="请确认课室设备清单">
      <div class="dialog-conent">
        <template v-for="item in facility">
          <div class="conent-item ">
            <span class="name">{{item.name}}</span>
            <span class="input">X{{item.number}}</span>
          </div>
        </template>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import "@/assets/less/views/classroom/classroomRevertEdit.less";
import { SUCCESS_CODE } from "@/apis/notification";
export default {
  name: "classroomAppointmentDetail",
  props: ["personnel"],
  data() {
    return {
      detail: {},
      appointment: {},
      activedTime: [],
      checkboxList: [],
      isCheckedAll: false,
      calendarShow: false,
      showDialog: false,
      showDialogEdit: false,
      contactName: "",
      contactMobileNumber: "",
      facility: [],
      lockList: [],
      isClick: false,

      radioDatesInd: null,
      nowTime: ""
    };
  },
  computed: {
    devices() {
      return this.detail && this.detail.facilitys && this.detail.facilitys.map(item => `${item.name}×${item.number}`).join(", ");
    },
    pictures() {
      return this.detail && this.detail.attachments && this.detail.attachments.map(item => item.url);
    }
  },
  watch: {
    checkboxList(newvalue, oldvalue) {
      this.isCheckedAll = this.checkboxList.length == this.facility.length;
    }
  },
  methods: {
    // /classroom_appointments/status
    // +++++++++++++++++
    init() {
      this.isClick = false;
      this.getClassroomDetail();
      this.getClassroomAppointmentDetail();
      this.nowTime = this.$moment().format("YYYY-MM-DD");

      this.getLockList();
    },
    //获取课室信息
    async getClassroomDetail() {
      let ret = await this.$api.getClassroomDetail({
        params: {
          useType: "APPLY_USER"
        },
        placeholder: {
          id: this.$route.query.classroomId
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.detail = ret.data;
      if (ret.data.attachments) {
        this.detail.attachments = await this.getAppendixes(ret.data.attachments);
        // console.log(999, await this.getAppendixes(ret.data.attachments));
      }
      this.facility = ret.data.facilitys.map(val => {
        return { ...val, remark: "" };
      });
    },
    //获取课室预约详情
    async getClassroomAppointmentDetail() {
      // let ret = await this.$api.getClassroomAppointmentDetail({
      //   placeholder: {
      //     id: this.$route.query.appointmentId
      //   }
      // });
      // if (ret.code != SUCCESS_CODE) return;
      // this.appointment = ret.data;
      this.appointment = JSON.parse(this.$utils.getSession("classroomRevertEdit"));
    },
    // 课室设备清单 登记
    async postClassroomAppointmentsChecks() {
      let { classroomId, appointmentId } = this.$route.query;
      let params = {
        checkData: "",
        classroomId,
        checkType: this.status == "USING" ? "CHECK_IN" : "CHECK_OUT"
      };
      params.checkData = JSON.stringify(
        this.facility.map((val, index) => {
          return { ...val, check: this.checkboxList.includes(val.id) };
        })
      );
      let ret = await this.$api.postClassroomAppointmentsChecks({
        params,
        placeholder: {
          appointmentId
        }
      });
      if (ret.code != SUCCESS_CODE) return;
      let id = this.appointment.dates[this.radioDatesInd - 1].id;
      let type;
      if (this.status == "USING") type = "OPEN_DOOR";
      if (this.status == "RETURNED") type = "CLOSE_DOOR";
      if (this.status == "CHECKED") type = "CONFIRM";
      this.postClassroomAppointmentsAppointmentIddatesTime(id, type);
      if (this.status == "CHECKED") {
        let id = this.appointment.dates[this.radioDatesInd - 1].id;
        this.putClassroomAppointmentsAppointmentIdDatesStatus(id);
      }
      setTimeout(() => {
        this.$router.go(-1);
      }, 1500);
      // this.putClassroomAppointmentsStatus();
    },
    // 修改课室预约状态
    async putClassroomAppointmentsStatus() {
      let params = {
        ids: [this.$route.query.appointmentId],
        status: this.status
      };
      let ret = await this.$api.putClassroomAppointmentsStatus({
        params
      });
      if (ret.code != SUCCESS_CODE) return;
      this.init();
    },
    async postClassroomAppointmentsAppointmentIddatesTime(id, type) {
      let placeholder = { appointmentId: this.$route.query.appointmentId };
      let ret = await this.$api.postClassroomAppointmentsAppointmentIddatesTime({
        params: { id, type },
        placeholder
      });
      if (ret.code != SUCCESS_CODE) return;
    },
    async putClassroomAppointmentsAppointmentIdDatesStatus(id) {
      let params = {
        ids: [id]
      };
      let placeholder = { appointmentId: this.$route.query.appointmentId };
      let ret = await this.$api.putClassroomAppointmentsAppointmentIdDatesStatus({
        params,
        placeholder
      });
      if (ret.code != SUCCESS_CODE) return;
    },
    submit(status) {
      this.showDialog = true;
      this.status = status;
      this.$nextTick(() => {
        this.$refs.checkboxGroup.toggleAll(true);
      });
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll(this.isCheckedAll);
    },
    changeCheckbox() {
      // let isCheckedAll = this.checkboxList.length == this.facility.length;
      // this.$refs.checkboxGroup.toggleAll();
    },
    //获取解密图片url
    async getAppendixes(par) {
      let urls = par
        .map(val => {
          return val.attachmentUrl;
        })
        .join(",");
      let ret = await this.$api.getAppendixFilePath({ params: { urls } });
      return par.map((val, ind) => {
        return { ...val, url: ret.data[ind] };
      });
    },
    dialogConfirm() {
      this.isClick = true;
      this.postClassroomAppointmentsChecks();
    },
    pictureClick(index) {
      this.$utils.previewImage({
        images: this.pictures,
        startPosition: index
      });
    },
    async getLockList() {
      let { classroomId } = this.$route.query;
      let ldapParams = {
        AND: {
          syntax: [{ attr: "id", value: classroomId, operator: "equalTo" }]
        }
      };
      let query = this.$utils.genLdapStr(ldapParams);
      let lockList = await this.$api.getClassroomLockIds({ params: { query, pageNumber: 0, pageSize: 10 } });
      if (!lockList.data[0].locks || !lockList.data[0].locks.length) {
        return (this.lockList = []);
      }
      let ret = await this.$api.getLockList({ params: { requestSource: "NB", lockIds: lockList.data[0].locks.map(item => item.lockId).toString() } });
      if (ret.code != SUCCESS_CODE) return;
      this.lockList = lockList.data[0].locks;
    },
    async getPasswordByLockId(lockIdx, appointmentIdx) {
      let lockId = this.lockList[lockIdx].lockId;
      let startTime = this.appointment.dates[appointmentIdx].borrowStartTime;
      let endTime = this.appointment.dates[appointmentIdx].borrowEndTime;
      let params = {
        validStartTime: this.$moment(startTime).unix(),
        validEndTime: this.$moment(endTime).unix(),
        startTime: this.$moment(startTime).toISOString(),
        endTime: this.$moment(endTime).toISOString()
      };
      let ret = await this.$api.getPasswordByLockId({ params, placeholder: { lockId } });
      if (ret.code != SUCCESS_CODE) return;
      this.lockList[lockIdx].password = ret.data;
      this.$set(this.lockList, lockIdx, this.lockList[lockIdx]);
    }
  },
  created() {
    this.init();
  }
};
</script>
